<template>
  <mo-main class="mo-main">
    <slot></slot>
  </mo-main>
</template>

<script>
export default {
  name: 'ContentsArea',
}
</script>

<style>
.mo-main{
  overflow-x: hidden;
  overflow-y: auto;
}
</style>